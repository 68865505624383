.bg {
/* The image used */

/* Full height */
height: 100vh;

/* Center and scale the image nicely */
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}
.text-banner{
    font-family: 'Roboto Condensed', sans-serif;
}